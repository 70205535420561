/** @jsx jsx */
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, jsx, Button } from "theme-ui";
import * as Yup from "yup";
import PrimaryLayout from "../../components/layout/primary/primary";
import AccountTopMenu from "../../components/top-menus/account/account-top-menu";
import { getJwtTokenIdentifier } from "../../services/auth-service";
import { UpdateUserEmail } from "../../services/user-service";

const AccountEmailPage: React.FC<any> = () => {
  return (
    <PrimaryLayout topMenuChild={AccountTopMenu}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Container py="40px" sx={styles.container}>
        <div className="row">
          <div sx={styles.wrapper} className="col-lg-7">
            <UserEmailForm />
          </div>
        </div>
      </Container>
    </PrimaryLayout>
  );
};

const UserEmailSchema = Yup.object().shape({
  email: Yup.string().email("Email is invalid").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const UserEmailForm = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(true);

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      enableReinitialize={true}
      validationSchema={UserEmailSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        setLoading(true);

        await UpdateUserEmail({
          userId: getJwtTokenIdentifier(),
          email: values["email"],
          password: values["password"],
        }).then((result) => {
          setError(!result.toLowerCase().includes("success"));
          setMessage(result);
        });
        setLoading(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form id="UserEmailForm">
          <h1 sx={styles.header}>My Email</h1>
          <hr className="bar-indent-center" />
          <div className="row" sx={styles.form.details}>
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label htmlFor="email" sx={styles.form.label}>
                  New Email
                </label>
                <Field
                  name="email"
                  id="email"
                  className="form-control"
                  sx={styles.form.input}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label htmlFor="password" sx={styles.form.label}>
                  Password
                </label>
                <Field
                  name="password"
                  id="password"
                  type="password"
                  className="form-control"
                  sx={styles.form.input}
                />
              </div>
            </div>
          </div>
          <div>
            <Button
              sx={styles.button}
              id="submitUpdateEmail"
              type="submit"
              disabled={isSubmitting}
            >
              Save Email Template
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const styles = {
  wrapper: {
    border: "1px solid #e4e4e4",
    boxShadow: "0 2px 45px 0 rgb(0 0 0 / 6%)",
    transition:
      "background 0.3s,border 0.3s,border-radius 0.3s,box-shadow 0.3s",
    padding: "30px 50px 30px 50px",
    borderRadius: "6px",
    background: "white",
    position: "relative",
    margin: "0 auto",
    "@media only screen and (max-width: 768px)": {
      padding: 20,
    },
  },
  container: {
    "@media only screen and (max-width: 768px)": {
      paddingLeft: "10px!important",
      paddingRight: "10px!important",
    },
  },
  header: {
    textAlign: "center",
  },
  additonal: {
    fontSize: "32px",
    fontWeight: "600",
  },

  input: {
    error: {
      fontSize: "13px",
      paddingBottom: "5px",
      color: "#dc3545",
    },
  },
  form: {
    details: {
      marginTop: 30,
      marginBottom: 30,
    },
    label: {
      fontWeight: "600",
      textTransform: "uppercase",
      marginBottom: "0.5em",
      color: "#2F3E57",
    },
    input: {
      width: "100%",
      fontSize: "14px",
      fontWight: "400",
      borderRadius: 6,
      padding: "10px",
      opacity: ".7",
      marginBottom: "10px",
      borderWidth: "1px",
      borderStyle: "solid",
      boxShadow: "none",
      transition: "border-color 0.2s ease",
      borderColor: "#E4E4E4",
      "&:focus": {
        outline: "none",
        boxShadow: "none",
        borderColor: "darkgray",
      },
      error: {
        fontSize: "13px",
        marginTop: "-5px",
        paddingBottom: "5px",
        color: "#dc3545",
      },
    },
  },
  error: {
    textAlign: "center",
    fontWeight: 500,
    fontSize: "18px",
    textTransform: "uppercase",
    fail: {
      color: "#dc3545",
    },
    success: {
      color: "#6da373",
    },
  },
  button: {
    fontSize: "14px",
    color: "white",
    fontWeight: "600",
    padding: "12.5px 25px",
    borderRadius: "2em",
    background: "#00d4c5",
    width: "100%",
    textTransform: "uppercase",
  },
};

export default AccountEmailPage;
